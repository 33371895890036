<template>
  <div v-if="contractDetails.open && blockMessage" class="neon noselect">
    <div class="btn gold" @click="connectWallet">{{ blockMessage }}</div>
  </div>
  <div v-else-if="contractDetails.open && connected" class="neon minting-form noselect">
    <div class="row">
      <div class="btn gold mint" @click="mintAction">
        <img v-if="minting" src="../assets/img/spinner.svg">
        <div class="caption">{{ minting ? 'WAIT...' : 'MINT' }}</div>
      </div>
      <div class="glass violet price">{{ contractDetails.mintingPrice }} ETH</div>
    </div>
    <div class="row">
      <div class="glass violet less" @click="buyLess">-</div>
      <div :class="['glass', 'oppal', 'amount', { 'shake': shakeAmountBox }]">{{ purchaseAmount }}</div>
      <div class="glass violet more" @click="buyMore">+</div>
    </div>
    <p>Connected with {{ providerName }}<br>{{ walletShortAddress }} ({{ walletBalance }} ETH)</p>
  </div>
  <div v-else-if="contractDetails.open" class="neon noselect">
    <div class="btn gold" @click="connectWallet">{{ installMetamask ? 'Install Metamask' : 'Connect your wallet' }}</div>
  </div>
  <div v-else class="neon noselect">
    <a href="https://discord.gg/nqFsHCucyc" target="_blank" class="btn gold">Join Our Discord</a>
  </div>
</template>

<script>
let purchaseAmount = 0
let shakeAmountBox = false
export default {
  name: 'MintingForm',
  props: {
    connected: {
      type: Boolean,
      default: false
    },
    contractDetails: {
      type: Object
    },
    providerName: {
      type: String
    },
    connectWallet: {
      type: Function
    },
    disconnectWallet: {
      type: Function
    },
    mint: {
      type: Function
    },
    listener: {
      type: Function
    },
    installMetamask: {
      type: Boolean,
      default: true
    },
    minting: {
      type: Boolean,
      default: false
    },
    blockMessage: {
      type: String
    },
    walletBalance: {
      type: Number
    },
    walletShortAddress: {
      type: String
    }
  },
  data() {
    return {
      purchaseAmount,
      shakeAmountBox
    }
  },
  created: function() {
    // console.log('===============================================')
    // console.log(this.connected, this.blockMessage)
    // console.log('===============================================')
  },
  beforeUpdate: function() {
    // console.log('===============================================')
    // console.log(this.connected, this.blockMessage)
    // console.log('===============================================')
  },
  methods: {
    buyLess: function() {
      this.listener()
      if (this.purchaseAmount > 0) {
        --this.purchaseAmount
        this.shakeAmountBox = false
      } else {
        this.shakeAmountBox = true
      }
    },
    buyMore: function() {
      this.listener()
      const { mintingMax, holdingMax, whitelisted } = this.contractDetails
      const max = whitelisted ? holdingMax : mintingMax
      if (this.purchaseAmount < max) {
        ++this.purchaseAmount
        this.shakeAmountBox = false
      } else {
        this.shakeAmountBox = true
      }
    },
    mintAction: function() {
      if (this.purchaseAmount > 0) {
        this.mint(this.purchaseAmount)
      } else {
        this.shakeAmountBox = true
      }
    }
  }
}
</script>
