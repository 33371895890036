<template>
  <div class="slider noselect">
    <div class="arrow left" @click="nextCard(false)"></div>
    <div class="neon gold card" v-for="slide in slider" :key="slide.id" v-show="slide.id == sliderIndex ? true : false">
      <img id="card-img" :src="slide.img" alt=""/>
      <p id="card-text" v-html="slide.text"></p>
    </div>
    <div class="arrow right" @click="nextCard(true)"></div>
  </div>
</template>

<script>
import { slider } from '../config/data'
const slideCount = slider.length
let current = 0
let sliderIndex = slider[current].id
const nextCard = function(next) {
  if (next) {
    current = ++current % slideCount
  } else {
    current = current == 0 ? slideCount -1 : --current % slideCount
  }
  this.sliderIndex = slider[current].id
}
export default {
  name: 'Cardousel',
  data: function() {
    return {
      slider,
      sliderIndex
    }
  },
  methods: {
    nextCard
  }
}
</script>