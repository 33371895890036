<template>
  <transition name="modal-fade">
    <div class="modal-backdrop noselect">
      <div
        :class="['modal', { 'error': error, 'success': success, 'info': error == success }]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            <h3 v-html="title"></h3>
            <p v-html="body"></p>
          </slot>
        </section>

        <footer class="modal-footer">
          <button
            type="button"
            :class="['btn', { 'error': error, 'success': success, 'info': error == success }]"
            @click="close"
            aria-label="Close modal"
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    error: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    body: {
      type: String
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>
