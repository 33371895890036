<template>
  <nav :class="['noselect', 'icon-list', { 'is-nav': isnav }]">
    <a v-for="link in social" :key="link.name" :href="link.url" target="_blank" class="icon" :aria-label="link.name">
      <span :class="['icon', link.class]"></span>
    </a>
  </nav>
</template>

<script>
import { social } from '../config/data.js'
export default {
  name: 'Nav',
  props: ['isnav'],
  data() {
    return {
      social
    }
  }
}
</script>