export const social = [{
  name: 'Discord',
  class: 'discord',
  url: 'https://discord.gg/nqFsHCucyc'
}, {
  name: 'Twitter',
  class: 'twitter',
  url: 'https://twitter.com/StarmanNFT'
}]

export const slider = [
  { id: 0, img: require('../assets/img/cardousel/one.png'), text: 'The Starman NFT Experience is a collectible NFT game and the first of its kind!' },
  { id: 1, img: require('../assets/img/cardousel/two.png'), text: 'NFT ("Token") holders are invited to inmerse themselves in the world of Starman.' },
  { id: 2, img: require('../assets/img/cardousel/three.png'), text: 'Discover Story elements, solve puzzles and collect achievements.' },
  { id: 3, img: require('../assets/img/cardousel/four.png'), text: 'Players can eventually, literally, step into the world of Starman and join the adventure' }
]

export const sections = [{
  class: 'blue',
  content: [{
    title: 'The Starman NFT Experience',
    text: `
      <p>The Starman NFT Experience is a collectible NFT game and the first of its kind! NFT ("Token") holders are invited to immerse themselves in the world of starman.</p>
      <p>Each Founder’s NFT comes with access to the original five episodes, future episodes as airdropped access tokens, and a claimable layer 2 card pack for the Starman NFT story driven card game.</p>`
  }]
}, {
  class: 'violet',
  content: [{
    title: 'How to Play',
    text: `
      <p>Token holders become players when they register their wallet address and use their NFT assets to discover and claim achievement tokens through recreating moments from The Adventures of Starman universe.</p>
      <p>Discovery of story elements involves holding certain combinations of 2, 3, or 5 NFTs. For example a two "card" recipe might be a Starman NFT and a Roadster NFT. A player would need to have 1 Starman NFT and 1 Roadster NFT in the same wallet. If the combination is valid the player will have the opportunity to mint an achievement.</p>`,
    banner: require('../assets/img/sections/play.jpeg')
  }]
}, {
  class: 'gold',
  content: [{
    title: 'Star Portals',
    text: `
      <p>Players are adventurers, discovering the world of Starman, and gaining access to more game elements and story as they continue to solve the combination achievements. Upon completing of the main storyline quest, players can claim a completion badge that will unlock access to a physical Starman NFT Experience "Star Portal".</p>
      <p>After completing the final achievements in a quest line you will be presented with a badge. Upon claiming your badge you will be rewarded with access to special scenarios in the upcoming combat game and limited NFT Profile Art.</p>
      <p></p>`,
    banner: require('../assets/img/sections/portals.jpeg')
  }, {
    title: 'Episodes & Drops',
    text: `
      <p>All Founders token holders will receive 1st edition airdrops (1 per token) for the next 10 episodes of The Adventures of Starman Series. Founders will also receive drops of special releases along the way.</p>
      <p></p>`,
    banner: require('../assets/img/sections/drops.jpeg')
  }]
}, {
  class: 'violet',
  content: [{
    title: 'Registered Limited Launch',
    text: `
    <p>We have a multi year plan for expansion of the Starman NFT Experience across mixed mediums. <br/> <br/> We have already delivered multiple features, and as sell-through goals are hit we will continue development on bringing more components to life.</p>
    <p>StarmanNFT.app - Completed<br/>Card Pack Claiming & Opening: Completed<br/>Episode 1-5 Access - February 2023<br/>Story Mode Game Release - April 2023<br/>25% Mint Out: Episodes 6 Developement Begins<br/>30% Mint Out: Trading Platform Development Begins<br/>50% Mint Out: Combat Game Development Begins</p>`
  }]
}]

export const team = [{
  name: 'Eli Burton',
  image: require('../assets/img/bio/eli.jpg'),
  bio: `- Creator, Producer, Co-Writer. Creator and Producer of The Adventures of Starman. Worked as a Director of Product in software with global teams.` 
}, {
  name: 'Ronald Acosta',
  image: require('../assets/img/bio/ronald.jpg'),
  bio: ` - Chief Illustrator. Life long illustrator and artist. Worked as an actor, videographer, and storyboard artist prior to moving into comics full time. board artist prior to moving into comics full time.` 
}, {
  name: 'Dani Vierra',
  image: require('../assets/img/bio/dani.png'),
  bio: ` - NFT Experience Architect. Player and Maker of Games, and Miss Nevada International 2019.` 
}, {
  name: 'Paul Huxley',
  image: require('../assets/img/bio/paul.jpg'),
  bio: ` - Writer. Paul is a passionate writer and filmmaker based in the UK. He has written everything from novels to feature films and comic books.` 
}, {
  name: 'Ross Hughes',
  image: require('../assets/img/bio/ross.jpg'),
  bio: ` - Began working as a colorist in 2005 while also working for United Space Alliance in the Flight Crew Equipment IPT.  Following the retirement of the Space Shuttle, Ross turned to coloring full-time.  He has worked for Marvel, and on numerous titles for DC Comics and IDW as part of Hi-Fi Studios, including Superman, Batman, Wonder Woman, Justice League, Flash, Green Lantern, Suicide Squad, Teen Titans, Scooby Doo Apocalypse, Doctor Who and many others.` 
}
]

export const messages = {
  loading: {
    type: 'loading',
    title: 'Please Wait',
    body: '<p>Currently</p>Mining'
  },
  success: {
    type: 'success',
    title: 'Transaction Completed',
    body: null
  },
  error: {
    type: 'error',
    title: 'Transaction Failed',
    body: null
  },
  installMetaMask: {
    type: 'info',
    title: 'Install MetaMask',
    body: '<p>You need to install MetaMask.</p>Get it <a target="_blank" href="https://metamask.io/download">from here</a>'
  }
}
