export function shorten(string, head = 6, tail = 4) {
  if (string && string.length) {
    const begin = string.slice(0, head)
    const end = string.slice(-tail)
    return `${begin} ... ${end}`
  }
}

export function environmentNetworks(infuraKey) {
  return {
    main: {
      name: 'main',
      chainId: 1,
      rpcUrl: `https://mainnet.infura.io/v3/${infuraKey}`,
      explorer: 'https://etherscan.io'
    },
    staging: {
      name: 'rinkeby',
      chainId: 4,
      rpcUrl: `https://rinkeby.infura.io/v3/${infuraKey}`,
      explorer: 'https://rinkeby.etherscan.io'
    },
    development: {
      name: 'private',
      rpcUrl: process.env.VUE_APP_DEV_RPC_URL,
      chainId: process.env.VUE_APP_DEV_CHAIN_ID,
      explorer: process.env.VUE_APP_DEV_EXPLORER_URL
    }
  }
}
