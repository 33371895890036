<template>
    <Nav isnav="true" />

    <img class="main-logo" src="./assets/img/logo.png" alt="Starman NFT Experience logo">

    <Cardousel/>

    <MintingForm
      :installMetamask="installMetamask"
      :contractDetails="contractDetails"
      :connectWallet="connectWallet"
      :disconnectWallet="disconnectWallet"
      :connected="connected"
      :blockMessage="blockMessage"
      :providerName="providerName()"
      :mint="mint"
      :walletBalance="walletBalance"
      :purchaseConfirmed="purchaseConfirmed"
      :purchaseFailed="purchaseFailed"
      :listener="listener"
      :minting="minting"
      :walletShortAddress="walletShortAddress"
    />

    <div v-for="section in sections" :key="section.index" :class="['neon', section.class]">
      <div v-for="content in section.content" :key="content.title">
        <h2>{{ content.title }}</h2>
        <div v-html="content.text"></div>
        <p v-if="content.banner" class="banner"><img :src="content.banner" alt="content.title"/></p>
      </div>
    </div>

    <div class="neon gold">
      <h2>The Team</h2>
      <iframe class="video" src="https://www.youtube.com/embed/aNocZ26MBOQ" title="Who are we"></iframe>
      <div class="member" v-for="member in team" :key="member.name">
        <div class="photo"><img :src="member.image" :alt="member.name"/></div>
        <div class="bio"><span>{{ member.name }}</span>{{ member.bio }}</div>
      </div>
    </div>

    <div class="neon">
      <a href="https://discord.gg/nqFsHCucyc" target="_blank" class="btn gold">Join Our Discord</a>
    </div>

    <div class="footer">
      <img src="./assets/img/logo-footer.png" alt="Starman NFT Experience">
      <Nav />
    </div>

    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      :success="success"
      :error="error"
      :title="actionResult"
      :body="userMessage"
    />
</template>

<script>
import Modal from './components/Modal'
import Nav from './components/Nav.vue'
import Cardousel from './components/Cardousel.vue'
import MintingForm from './components/MintingForm.vue'
import { sections, team } from './config/data'
import { Config, ContractDetails, ProviderOptions } from './config'
import { shorten } from './utils'
import axios from 'axios'
import Web3 from 'web3'
import Web3Modal from 'web3modal'
let provider = null
let web3Driver = null
let installMetamask = true
let blockMessage = null
let walletAddress = null
let walletBalance = null
let trxInProgress = false
let purchaseConfirmed = false
let purchaseFailed = false
let errorMessage = null
let actionResult = null
let userMessage = null
let minting = false
let walletShortAddress = null
if (Object.keys(ProviderOptions).length > 1 || !ProviderOptions['custom-metamask']) {
  installMetamask = false
}
const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions: ProviderOptions // required
})
let connected = false
const connectWallet = async function(event) {
  event.preventDefault()
  try {
    this.provider = await web3Modal.connect()
    setEventListeners(this, this.provider)
    this.web3Driver = new Web3(this.provider)
    this.connected = true
    const accounts = await this.web3Driver.eth.getAccounts()
    this.walletAddress = accounts[0]
    this.walletShortAddress = shorten(this.walletAddress)
    const balance = await this.web3Driver.eth.getBalance(this.walletAddress)
    this.walletBalance = Number((balance/100000000/10000000000).toFixed(6))
  } catch(err) {
    // Restart the modal uppon any error
    web3Modal.clearCachedProvider()
    this.connected = false
  }
}
const providerName = function() {
  let name = ''
  if (this.provider) {
    if (this.provider.isMetaMask) {
      name = 'MetaMask'
    } else if (this.provider.isCoinbaseWallet) {
      name = 'Coinbase Wallet'
    } else if (this.provider.wc) {
      name = 'Wallet Connect'
    } else if (this.provider.isFortmatic) {
      name = 'Fortmatic'
    }
  }
  return name
}
const disconnectWallet = function() {
  web3Modal.clearCachedProvider()
  this.connected = false
}
const setEventListeners = (_app, provider) => {
  // Subscribe to accounts change
  provider.on('accountsChanged', (accounts) => {
    console.log(accounts)
  })
  // Subscribe to chainId change
  provider.on('chainChanged', (chainId) => {
    if (chainId != Config.network.chainId) {
      _app.blockMessage = `Please, select ${Config.network.name} network`
    } else {
      _app.blockMessage = ''
    }
  })
  // Subscribe to provider connection
  provider.on('connect', () => {
    _app.connected = true
  })
  // Subscribe to provider disconnection
  provider.on('disconnect', () => {
    _app.connected = false
  })
}
const wrappedCallback = function(master) {
  return function (error, result) {
    if (error) {
      master.purchaseFailed = true
      master.errorMessage = result
    } else {
      master.trxInProgress = result
    }
  }
}
const mint = async function(tokenAmount) {
  if (tokenAmount > 0) {
    this.minting = true
    const signUrl = process.env.VUE_APP_SIGNING_ENDPOINT
    let payload = {
      wallet: this.walletAddress,
      tokenAmount
    }
    let data = {
      success: true,
      result: {
        token: '0x6c00000000000000000000000000000000000000000000000000000000000000',
        hash: '0x000001'
      }
    }
    if (this.contractDetails.whitelisted) {
      const response = await axios.post(signUrl + '/wallets/sign', payload)
      data = response.data
    }
    if (data.success) {
      const { hash } = data.result
      const feeMultiplier = process.env.VUE_APP_GAS_MULTIPLIER
      const ethAmount = tokenAmount * this.contractDetails.mintingPrice
      try {
        const weiAmount = this.web3Driver.utils.toWei(ethAmount.toFixed(4).toString(), 'ether')
        let options = {
          from: this.walletAddress,
          value: weiAmount,
        }
        const gasLimit = await this.web3Driver.eth.estimateGas(options);
        let gasMultiplier = tokenAmount * feeMultiplier;
        if (tokenAmount == 1) {
          gasMultiplier = tokenAmount * feeMultiplier * 2
        }
        options = {
          ...options,
          to: this.contractDetails.contractAddress,
          gas: parseInt(gasMultiplier * gasLimit),
        }
        const { abi, contractAddress } = this.Config
        const contract = new this.web3Driver.eth.Contract(abi, contractAddress)
        const response = await contract.methods.mint(hash, tokenAmount).send(options, wrappedCallback(this))
        payload = {
          wallet: this.walletAddress,
          tokenAmount,
          trx: response.transactionHash
        }
        axios.post(signUrl + '/trx/record', payload).then(console.log).catch(console.log)
        this.minting = false
        this.purchaseConfirmed = true
        this.success = true
        this.error = false
        this.actionResult = 'Congrats! The transaction was successful'
        const { symbol, name } = this.contractDetails
        this.userMessage = `
          Your purchase for <span class="remark">${tokenAmount} ${symbol} (${name})</span> is completed<br>
          You can check it following the link:<br>
          <a target="_blank" href="${this.Config.network.explorer}/tx/${response.transactionHash}">${response.transactionHash}</a>`
        this.isModalVisible = true
      } catch(err) {
        console.log(err)
        let msg = 'Sorry... There was an unexpected error while trying to complete the transaction'
        if (err && err.message) {
          if (err.message.indexOf('insufficient funds') > -1) {
            msg = 'Sorry... Your wallet does not have enough funds'
          } else if (err.message.indexOf(': revert') > -1) {
            const cause = err.message.substr(err.message.indexOf(': revert') + 8)
            msg = `The transaction has been reverted because of ${cause}`
          } else {
            msg = `The transaction has been reverted by the EVM<br>
            <a target="_blank" href="${this.Config.network.explorer}/tx/${this.trxInProgress}">${this.trxInProgress}</a>`
          }
        }
        this.minting = false
        this.purchaseFailed = true
        this.errorMessage = msg
        this.error = true
        this.sucess = false
        this.actionResult = `Sorry. That didn't work`
        this.userMessage = msg
        this.isModalVisible = true
      }
    } else {
      this.minting = false
      this.error = false
      this.success = false
      this.actionResult = `Sorry. That didn't work`
      this.userMessage = `Your wallet:<br>${this.walletAddress}<br>is not authorized to mint at this time`
      this.isModalVisible = true
    }
  }
}
const showModal = function() {
  this.isModalVisible = true
}
const closeModal = function() {
  this.isModalVisible = false
}
let contractDetails = {
  open: Config.open
}
export default {
  name: 'App',
  components: {
    Modal,
    Cardousel,
    Nav,
    MintingForm
  },
  data() {
    return {
      Config,
      sections,
      team,
      isModalVisible: false,
      contractDetails,
      connected,
      installMetamask,
      blockMessage,
      provider,
      web3Driver,
      walletAddress,
      walletBalance,
      trxInProgress,
      purchaseConfirmed,
      purchaseFailed,
      errorMessage,
      actionResult,
      userMessage,
      minting,
      walletShortAddress
    }
  },
  async created() {
    this.contractDetails = await ContractDetails(Config.contract)
  },
  methods: {
    connectWallet,
    disconnectWallet,
    showModal,
    closeModal,
    providerName,
    mint,
    listener: function() {
      this.trxInProgress = false
      this.purchaseConfirmed = false
      this.purchaseFailed = false
      this.errorMessage = null
    }
  }
}
</script>
